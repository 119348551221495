<template>
  <div class="popover" v-if="selected">
    <div class="content">
      <!-- <button class="close" @click="closeWindow"><Fa fa="close" /></button> -->
      <VueAgile
        :navButtons="false"
        :centerMode="true"
        :slidesToShow="1"
        :infinite="false"
        class="imgs"
      >
        <img
          v-for="img in selected.images"
          :key="img"
          :src="img"
          alt=""
          class="slide"
        />
      </VueAgile>
      <div class="info">
        <h3>{{ selected.title }}</h3>
        <div class="tags">
          <span v-for="tag in selected.tags" :key="tag">{{ tag }}</span>
        </div>
        <div class="description">
          {{ selected.longDescription }}
        </div>
        <div class="tags links">
          <a
            class="source-demo"
            target="_blank"
            :href="selected.demo"
            v-if="selected.demo != ''"
          >
            <div class="demo-text">Demo</div>
          </a>
          <a
            class="github-button"
            target="_blank"
            :href="selected.source"
            v-if="selected.source != ''"
          >
            <svg
              viewBox="0 0 16 16"
              width="16"
              height="16"
              class="octicon octicon-mark-github"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
              ></path>
            </svg>
            <div class="github-text">GitHub</div>
          </a>
        </div>
      </div>
    </div>
    <div class="background" @click="closeWindow"></div>
  </div>
</template>

<script>
// import Fa from './globals/Fa.vue';
import { VueAgile } from 'vue-agile';

export default {
  components: {
    // Fa,
    VueAgile,
  },
  props: {
    selected: Object,
    closeWindow: Function,
  },
};
</script>

<style lang="scss">
.agile {
  width: 100%;

  .agile__list {
    border-radius: 0.6rem 0.6rem 0 0;
  }

  &__nav-button {
    background: transparent;
    border: none;
    color: #ccc;
    cursor: pointer;
    font-size: 24px;
    transition-duration: 0.3s;

    &:hover {
      color: #888;
    }
  }

  &__dots {
    margin: 18px 0 10px 0 !important;
  }

  &__dot {
    margin: 0 2px !important;

    button {
      background-color: rgb(132, 132, 132);
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: 0.3s;
      width: 10px;
    }

    &--current {
      button {
        background-color: #eee;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.agile img {
  max-height: 100%;
  object-fit: contain;
}
.agile {
  img {
    max-height: 76vh;
    // border-radius: 12px 12px 0 0;
  }
}

.popover {
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow: auto;
  top: 0;
  display: flex;
  .background {
    background: #00000052;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
  }

  .content {
    border-radius: 0.6rem;
    cursor: initial;
    background: #1d1e21;
    max-width: 766px;
    margin: auto;
    width: -webkit-fill-available;
    position: relative;
  }
  .info {
    padding: 0 1.3rem 1rem;
    h3 {
      grid-area: txt;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.4;
      margin-bottom: 3px;
      margin-top: 0;
    }
    .description {
      margin-top: 0.6rem;
      font-size: 0.9em;
    }
    .tags {
      display: flex;
      gap: 0.7rem;
      font-weight: 500;
      font-size: 0.8rem;
      color: var(--txt-med);
      .source-demo {
        margin-top: 12px !important;
        border-radius: 0.25em;
        background: rgb(16, 16, 16);
        background: rgb(255, 255, 255);
        // background: var(--accent-4);
        color: white;
        color: black !important;
        cursor: pointer;
        padding: 5px 10px 4px 10px;
        background: linear-gradient(180deg, #f6f8fa, #ebf0f4 90%);
      }
      .github-button {
        display: flex;
        margin-top: 12px !important;
        border-radius: 4px;
        background: rgb(16, 16, 16);
        background: rgb(175, 164, 231);
        background: var(--accent-4);
        color: white;
        color: black !important;
        cursor: pointer;
        padding: 5px 8px 4px 8px;
        background: grey;
        border-radius: 0.25em;
        background: linear-gradient(180deg, #f6f8fa, #ebf0f4 90%);
        color: #24292f;
      }
      .github-text {
        margin: 1px auto auto 5px;
      }
      .demo-text {
        margin: 1px auto auto;
      }
    }
    .tags.links {
      a {
        color: white;
        text-decoration: none;
        margin-top: 0.6rem;
      }
    }
  }
  .info > span {
    display: block;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--txt-med);
  }
  .screenshots {
    padding: 1.3rem;
    img {
      max-height: 400px;
      max-width: 100%;
      margin: auto;
    }
  }
  .close {
    background: transparent;
    border: 0;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 100;
    background: rgb(0 0 0 / 39%);
  }
}

@media screen and (max-width: 760px) {
  .popover {
    .content {
      margin: auto 15px;
    }
  }
}
@media screen and (max-width: 420px) {
  .agile {
    img {
      max-height: 55vh !important;
    }
  }
  .popover {
    .info {
      h3 {
        font-size: 1.45rem;
      }
    }
  }
}
</style>
