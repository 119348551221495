<template>
  <kinesis-container :duration="400">
    <kinesis-element :strength="kinesisStrength()" type="depth_inv">
      <div
        class="card"
        @click="txt(item.id)"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <div
          class="overlay"
          :style="{
            background: `linear-gradient(0deg, #1d1e20 5%, rgba(33, 34, 37, 0.6) 45%, rgba(34, 35, 38, 0) 100%), url(${
              hover ? item.images[1] : item.images[0]
            }) top center/cover`,
          }"
        ></div>
        <div class="info">
          <kinesis-element :strength="2" type="depth_inv">
            <h3>{{ item.title }}</h3>
          </kinesis-element>
          <div class="tags">
            <span v-for="tag in item.tags" :key="tag">{{ tag }}</span>
          </div>
          <div class="description">
            {{ item.description }}
          </div>
        </div>
      </div>
    </kinesis-element>
  </kinesis-container>
</template>

<script>
import { KinesisContainer, KinesisElement } from 'vue-kinesis';
export default {
  components: { KinesisContainer, KinesisElement },
  data: () => ({ hover: false }),
  props: {
    item: Object,
    txt: Function,
  },
  mounted() {
    this.loadProjectImages();
  },
  methods: {
    loadProjectImages() {
      let image1 = new Image();
      image1.src = this.item.images[0].img;
      let image2 = new Image();
      image2.src = this.item.images[1].img;
    },
    kinesisStrength() {
      return window.innerWidth > 600 ? 7 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover .overlay {
  box-shadow: inset 0px -363px 292px -300px #26282980;
  opacity: 1;
}
.card {
  width: 320px;
  height: 480px;
  background: #212225;
  border-radius: 0.7rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: 0;
    border-radius: 0px 0px 2rem 2rem;
  }
  .overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 0.8rem;
    box-shadow: inset 0px 0px 0px 0px #2e2f31;
  }

  .info {
    padding: 0 1.3rem 1.6rem 1.3rem;
    z-index: 3;
    position: absolute;
    bottom: 0;
    h3 {
      grid-area: txt;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.4;
      margin-bottom: 0rem;
      margin-top: 0;
    }
    .tags {
      display: flex;
      gap: 0.7rem;
      font-weight: 500;
      font-size: 0.8rem;
      color: var(--txt-med);
    }
    .tags.links {
      a {
        color: white;
        text-decoration: none;
        margin-top: 0.6rem;
      }
    }
    .description {
      font-size: 0.9em;
    }
  }
}

@media screen and (max-width: 400px) {
  .card {
    width: auto;
    height: 420px;
  }
  h3 {
    font-size: 1.5em !important;
  }
}
</style>
