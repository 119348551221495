import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import faFa from './components/globals/FaFa.vue';

const app = createApp(App);

app.config.productionTip = false;

app.use(store);
app.use(router);
app.component('faFa', faFa);

app.mount('#app');
