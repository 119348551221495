<template>
  <Hero />
  <MainView :scrolledDown="!scrolledDown" />
</template>

<script>
import { debounce } from 'lodash';
import MainView from './components/MainView.vue';
import Hero from './components/Hero/MyHero.vue';

export default {
  name: 'Home',
  components: {
    MainView,
    Hero,
  },
  data: () => ({
    size: 'xl',
    scrolledDown: false,
  }),
  mounted() {
    window.onresize = debounce(this.detectSize, 50);
    window.addEventListener('scroll', debounce(this.detectScroll, 5));
    this.detectSize();
  },
  methods: {
    detectSize() {
      const width = window.innerWidth;
      let result = {};
      switch (true) {
        case width < 425:
          result = {
            size: 'mobile',
            sizeint: 425,
            range: [0, 425],
            includes: ['mobile'],
          };
          break;
        case width < 680:
          result = {
            size: 's',
            sizeint: 680,
            range: [426, 680],
            includes: ['mobile', 's'],
          };
          break;
        case width < 1024:
          result = {
            size: 'm',
            sizeint: 1024,
            range: [680, 1024],
            includes: ['mobile', 's', 'm'],
          };
          break;
        case width < 1300:
          result = {
            size: 'l',
            sizeint: 1300,
            range: [1025, 1300],
            includes: ['mobile', 's', 'm', 'l'],
          };
          break;
        case width < 1600:
          result = {
            size: 'xl',
            sizeint: 1600,
            range: [1301, 1600],
            includes: ['mobile', 's', 'm', 'l', 'xl'],
          };
          break;
        default:
          result = {
            size: 'xl',
            sizeint: 1600,
            range: [1301, 1600],
            includes: ['mobile', 's', 'm', 'l', 'xl'],
          };
      }
      this.size = {
        width: width,
        size: result.size,
        sizeint: result.sizeint,
        range: result.range,
        includes: result.includes,
      };
    },
    detectScroll() {
      this.scrolledDown = window.scrollY > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
#defaultCanvas0 {
  margin: 0;
  padding: 0;
  display: block;
}
.gradient-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(0deg, #1d1e21 0%, #1d1e2100 40%);
}
.splash {
  width: 100%;
  position: absolute;
  z-index: -10;
  top: 0;
  background: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.noise {
  position: absolute;
  /* background-image: url('noise.svg'); */
  width: 100%;
  height: 100px;
}
</style>
