import { createWebHistory, createRouter } from 'vue-router';
import Home from '../Home.vue';
import CV from '../CV.vue';

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/cv',
    component: CV,
  },
  {
    path: '/blog',
    beforeEnter() {
      window.location.href = 'https://devslashblog.netlify.app'; // Replace with your external URL
    },
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
